<template>
  <div class="user">
    <div class="form">
       <el-form :inline="true" size="small">
        <el-form-item label="用户名">
          <el-input v-model="queryInfo.name" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="queryInfo.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getUserList">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
        <el-button size="small" type="primary" @click="exportAll">导出</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 240px)"
    >
      <el-table-column type="index" label="序号" />
      <el-table-column label="用户" prop="name" />
      <el-table-column label="手机" prop="phone" />
      <el-table-column label="头像" prop="avatar">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.avatar"
            class="avatar"
            :src="scope.row.avatar"
            :preview-src-list="[scope.row.avatar]"
          />
          <div v-else class="avatar" />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          />
          <el-button
            v-if="!scope.row.necessary"
            icon="el-icon-delete"
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="pageSizes"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 模态 -->
    <el-dialog :title="formTitle" width="30%" :visible.sync="userFormVisible">
      <el-form
        :model="userForm"
        :rules="userRules"
        ref="userForm"
        size="medium"
        label-width="80px"
      >
        <el-form-item label="用户" prop="name">
          <el-input v-model="userForm.name" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input
            v-model="userForm.phone"
            :readonly="formType === 'edit'"
            :disabled="formType === 'edit'"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="userForm.password" placeholder="请输入密码" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input type="password" v-model="userForm.confirmPassword" placeholder="请确认密码" />
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <image-upload
            style="width: 100px; height: 100px"
            v-model="userForm.avatar"
            dir="user"
            @input="handleImageInput"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  deleteUser,
  addUser,
  updateUser,
  exportAll
} from '@/services/user'

export default {
  name: 'User',
  data () {
    return {
      tableData: [],
      total: 0,
      pageSizes: [10, 20, 30, 40, 50, 100],
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      userForm: {},
      userRules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        password: [
          { min: 6, max: 16, message: '最大长度在 6 到 16 个字符', trigger: 'blur' }
        ],
        confirmPassword: []
      },
      formTitle: '新增',
      formType: 'add',
      userFormVisible: false
    }
  },
  mounted () {
    this.userFormVisible = false
    this.getUserList()
  },
  methods: {
    async getUserList () {
      const { data } = await getUserList(this.queryInfo)
      this.tableData = data.data
      this.total = data.meta.total
    },
    handleAdd () {
      if (this.$refs.userForm) {
        this.$refs.userForm.resetFields()
      }
      const confirmPassword = (rule, value, callback) => {
        if (value !== this.userForm.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      const checkRequired = { required: true, message: '请输入密码', trigger: 'blur' }
      const checkConfirm = { required: true, message: '请确认密码', trigger: 'blur' }
      const checkConfirmPassword = { validator: confirmPassword, trigger: 'blur' }
      this.userRules.password.push(checkRequired)
      this.userRules.confirmPassword.push(checkConfirmPassword)
      this.userRules.confirmPassword.push(checkConfirm)
      this.formTitle = '新增'
      this.formType = 'add'
      this.userFormVisible = true
    },
    handleEdit (index, row) {
      if (this.$refs.userForm) {
        this.$refs.userForm.resetFields()
      }
      this.formTitle = '编辑'
      this.formType = 'edit'
      this.userForm = { ...row }
      this.userRules.password.pop()
      this.userRules.confirmPassword.pop()
      this.userFormVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('请确认操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteUser(row.id)
        this.getUserList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNum = newPage
      this.getUserList()
    },
    save () {
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          try {
            this.formType === 'add' ? await addUser(this.userForm) : await updateUser(this.userForm.id, this.userForm)
            this.userForm = {}
            this.userFormVisible = false
            this.getUserList()
            this.$message({
              type: 'success',
              message: `${this.formTitle}用户成功`
            })
          } catch (err) {
            this.$message({
              type: 'error',
              message: `${this.formTitle}用户失败`
            })
          }
        }
      })
    },
    cancel () {
      this.userForm = {}
      this.userFormVisible = false
    },
    reset () {
      delete this.queryInfo.name
      delete this.queryInfo.phone
      this.getUserList()
    },
    handleImageInput (val) {
      this.userForm.avatar = val
    },
    async exportAll () {
      const { data } = await exportAll()
      const blob = new Blob([data], { type: 'application/octet-stream,charset=UTF-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `管理员-${Date.now()}.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  box-sizing: border-box;
  padding: 15px;
  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 15px 0;
    .el-form .el-form-item {
      margin-bottom: 0;
    }
  }
  .avatar {
    width: 50px;
    height: 50px;
  }
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
}
</style>
